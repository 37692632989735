<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12" v-if="allTrainingCategories.results">
                <v-select
                  v-model="connectedTo"
                  :items="categories"
                  label="Training Category"
                  outlined
                  item-text="title"
                  item-value="_id"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Training Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="sort"
                  outlined
                  label="Sort"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="price"
                  outlined
                  label="Price"
                  class="mr-btm-minus"
                  :rules="[priceRules.nan]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-date-picker
                  v-model="dates"
                  range
                  :rules="dateRules"
                ></v-date-picker>
                <v-alert
                  v-if="dates.length < 2"
                  border="right"
                  color="red lighten-2"
                  dark
                >
                  Please select End date
                </v-alert>
                <v-alert
                  v-if="dates.length < 1"
                  border="right"
                  color="red lighten-2"
                  dark
                >
                  Please select both Start date
                </v-alert>
              </v-col>

              <v-col cols="12">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </v-col>
              <v-col cols="12">
                <img
                  :src="'https://app.necojobs.com.np' + imageUrl"
                  alt=""
                  class="imageold"
                />
              </v-col>
              <v-col cols="12">
                <label class="mb-2" style="font-weight: 500">Feature</label>
                <!-- <v-textarea              v-model="feature" outlined placeholder="Feature"> </v-textarea> -->
                <quill-editor
                  ref="myQuillEditor1"
                  v-model="feature"
                  :options="editorOption1"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </v-col>
              <v-col cols="12">
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="file"
                  @input="setImage"
                  class="imagebox"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to upload"
                    }}</span>
                  </label>
                </image-uploader>
              </v-col>
              <v-col cols="12">
                <h3 class="mb-2">Faqs</h3>
                <v-btn class="mb-5 primary" @click="addFaq">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in faqs"
                  :key="index"
                >
                  <div class="removeButton" @click="removeFaq(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-text-field
                    v-model="data.title"
                    outlined
                    :label="`Faq Title  ${index + 1}`"
                    class="mr-btm-minus"
                  ></v-text-field>
                  <v-textarea
                    v-model="data.description"
                    outlined
                    :label="`Faq Description  ${index + 1}`"
                    rows="3"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="mb-2">Course Objects</h3>
                <v-btn class="mb-5 primary" @click="addCouseObject">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in courseObjects"
                  :key="index"
                >
                  <div class="removeButton" @click="removeCourseObject(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-textarea
                    v-model="courseObjects[index]"
                    outlined
                    :label="`Course Objective  ${index + 1}`"
                    rows="2"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="mb-2">For Whom</h3>
                <v-btn class="mb-5 primary" @click="addForWhom">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in forWhom"
                  :key="index"
                >
                  <div class="removeButton" @click="removeForWhom(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-textarea
                    v-model="forWhom[index]"
                    outlined
                    :label="`For whom  ${index + 1}`"
                    rows="2"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="mb-2">Benefits</h3>
                <v-btn class="mb-5 primary" @click="addBenefit">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in benefits"
                  :key="index"
                >
                  <div class="removeButton" @click="removeBenefit(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-textarea
                    v-model="benefits[index]"
                    outlined
                    :label="`Benefit  ${index + 1}`"
                    rows="2"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="mb-2">Course Content</h3>
                <v-btn class="mb-5 primary" @click="addCourseContent">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in courseContent"
                  :key="index"
                >
                  <div class="removeButton" @click="removeCourseContent(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-text-field
                    v-model="data.title"
                    outlined
                    :label="`Course Title  ${index + 1}`"
                    class="mr-btm-minus"
                  ></v-text-field>
                  <quill-editor
                    ref="myQuillEditor1"
                    v-model="data.description"
                    :options="editorOption1"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="metaTitle"
                  outlined
                  label="Meta Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="metaKeywords"
                  outlined
                  label="Meta Keywords"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="metaDescription"
                  outlined
                  label="Meta Description"
                  class="mr-btm-minus"
                  rows="5"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="valid && dates.length < 2"
                  class="submitbutton btn-dark"
                  @click="validate"
                  :loading="submitLoading"
                >
                  Add {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-snackbar v-model="snakbar" v-if="_id != undefined">
        <span>Training Course Updated Successfully</span>
      </v-snackbar>
      <v-snackbar v-model="snakbar" v-if="_id == undefined">
        <span>Training Course Added Successfully</span>
      </v-snackbar>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddTraining",
  components: { PageHeader, ImageUploader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    statusRules: [(v) => !!v || "Status is required"],
    dateRules: [(v) => !!v || "Date is required"],
    sortRules: {
      required: (value) => !!value || "Sort is required.",
      nan: (v) => !isNaN(v) || "Sort should be number",
    },
    priceRules: {
      nan: (v) => !isNaN(v) || "Price should be number",
    },

    headercontent: {
      title: "Add Training",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    content: "<h2>I am Example</h2>",
    editorOption: {
      // Some Quill options...
    },
    editorOption1: {
      // Some Quill options...
    },
    sort: 0,
    dates: [],
    imageUrl: "",
    id: null,
    faqs: [],
    courseObjects: [],
    forWhom: [],
    benefits: [],
    connectedTo: undefined,
    submitLoading: false,
    snakbar: false,
    feature: "",
    categories: [],
    courseContent: [],
    price: 0,
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
  }),
  methods: {
    ...mapActions([
      "createTrainingCourse",
      "getSingleTraining",
      "getTrainingCategories",
    ]),
    async validate() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        let fd = new FormData();
        fd.append("title", this.title);
        fd.append("description", this.description);
        fd.append("status", this.status);
        if (this.dates.length >= 1) {
          fd.append("startDate", this.dates[0]);
        }
        if (this.dates.length == 2) {
          fd.append("endDate", this.dates[1]);
        }
        fd.append("image", this.image);
        fd.append("fileType", "images");
        fd.append("imageFile", "trainings");
        fd.append("connectedTo", this.connectedTo);
        fd.append("faqs", JSON.stringify(this.faqs));
        fd.append("courseObjects", JSON.stringify(this.courseObjects));
        fd.append("forWhom", JSON.stringify(this.forWhom));
        fd.append("benefits", JSON.stringify(this.benefits));
        fd.append("feature", this.feature);
        fd.append("courseContent", JSON.stringify(this.courseContent));
        fd.append("price", this.price);
        fd.append("metaTitle", this.metaTitle);
        fd.append("metaKeywords", this.metaKeywords);
        fd.append("metaDescription", this.metaDescription);

        if (this.id != null) {
          fd.append("id", this.id);
        }

        await this.createTrainingCourse(fd);

        this.submitLoading = false;
        this.snakbar = true;
      }
    },
    addFaq() {
      this.faqs.push({
        title: "",
        description: "",
      });
    },
    removeFaq(index) {
      this.faqs.splice(index, 1);
    },
    addCouseObject() {
      this.courseObjects.push("");
    },
    removeCourseObject(index) {
      this.courseObjects.splice(index, 1);
    },
    addForWhom() {
      this.forWhom.push("");
    },
    removeForWhom(index) {
      this.forWhom.splice(index, 1);
    },
    addBenefit() {
      this.benefits.push("");
    },
    removeBenefit(index) {
      this.benefits.splice(index, 1);
    },
    addCourseContent() {
      this.courseContent.push({
        title: "",
        description: "",
      });
    },
    removeCourseContent(index) {
      this.courseContent.splice(index, 1);
    },

    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log("Info", output.info);
      console.log("Exif", output.exif);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  computed: {
    ...mapGetters(["allTraining", "allTrainingCategories"]),
    editor() {
      let a = this.$refs.myQuillEditor1.quill;
      let b = this.$refs.myQuillEditor.quill;
      return { a, b };
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  async created() {
    if (this.$route.params.slug) {
      await this.getSingleTraining(this.$route.params.slug);
      this.title = this.allTraining.results.trainingTitle;
      this.sort = this.allTraining.results.sort;
      this.description = this.allTraining.results.description;
      this.feature = this.allTraining.results.feature;
      this.status = this.allTraining.results.status;
      (this.dates = [
        this.allTraining.results.startDate,
        this.allTraining.results.endDate,
      ]),
        (this.imageUrl = this.allTraining.results.imageUrl),
        (this.startDate = this.allTraining.results.startDate),
        (this.endDate = this.allTraining.results.endDate),
        (this.id = this.allTraining.results._id);
      this.faqs = this.allTraining.results.faqs;
      this.courseObjects = this.allTraining.results.courseObjects;
      this.forWhom = this.allTraining.results.forWhom;
      this.benefits = this.allTraining.results.benefits;
      this.connectedTo = this.allTraining.results.connectedTo;
      this.courseContent = this.allTraining.results.courseContent;
      this.price = this.allTraining.results.price;
      this.metaTitle = this.allTraining.results.metaTitle;
      this.metaKeywords = this.allTraining.results.metaKeywords;
      this.metaDescription = this.allTraining.results.metaDescription;
    }
    await this.getTrainingCategories();
    this.categories = [
      { title: "Select None", _id: undefined },
      ...this.allTrainingCategories.results,
    ];
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.imageold {
  width: 200px;
}

.repeaterBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  .removeButton {
    position: absolute;
    right: -25px;
    top: -25px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
